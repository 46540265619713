import SaasPage from "./landings/_saasPage";
import {graphql} from "gatsby";

export default SaasPage

export const frontmatter = {
  pageMetaData: {
    refKey: "saas",
    title: "Trust your SaaS App is secured & backed up.",
    description: "Automate backups for your SaaS. Files, servers, and storage. Connect any provider, quick recovery, and peace of mind.",
    author: "simplebackups.com"
  }
}

export const pageQuery = graphql`
  query CaseStudiesListSaasQuery {
    posts: allMdx(
      filter: {frontmatter: {layout: {eq: "casestudy"}}},
      sort: {fields: frontmatter___date, order: ASC},
      skip: 1,
      limit: 2,
      ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            userQuote
            userRole
            userName
            userPhoto
            companyShortDescription
            date(formatString: "DD-MM-YYYY")
          }
        }
      }
    }
  }
`